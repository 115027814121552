.header-text {
    text-align: center;
    /* color: #150578; */
    color: #4e0f8c;
}

.sub-header-text{
    text-align: center;
    width: 100%;
    /* color: #150578; */
    color: #4e0f8c;
    padding-top: 5%;
    padding-bottom: 4%;
    font-size: 1.8rem;
    font-weight:400;
}

.rounded-circle{
    border-radius: 50%;
    border: 4px solid #c2aafa;
    margin: 0;
    padding: 0;
}

h1{
    padding-top:3%;
    padding-bottom: 5%;
    font-weight: 400;
}

.heading{
    padding: 10% 0.5% 10% !important;
    text-align: center;
    /* color: #150578; */
    color: #4e0f8c;
}

h6{
    padding: 1%;
}

h4{
    padding: 1%
}

h7{
    font-weight: 500;
}

h5{
    padding-top: 0%;
}

.nav-text{
    /* color: #150578; */
    color: #4e0f8c;
    font-size: 1.05rem
}

.nav-link{
    border-radius: 0% !important;
}
.nav-link.active{
    border-bottom: 2px solid #4e0f8c !important;
    background-color: white !important ;
    color: #c2aafa !important;
}

 .card
{
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  border-radius: 5px;
  margin-bottom: 5%;
}

.work-icon{
    border-radius: 50% !important;
    margin-right: 5%;
}

.icon-no-border{
    margin-right: 5%;
}


.border{
    border: 1px solid #c2aafa;
    border-radius: 50% !important;
    margin-right: 5%;
}

.card-content{
    padding-top: 2%;
    padding-left: 2%;
}
.social-logo{
    width:18px;
    height:18px; 
}

.twitter-logo{
    width:18px;
    height:18px; 
}

.emoji{
    width: 32px;
    height: 32px;
    padding-bottom: 1%;
    padding-top: 0%;
}
.email-logo{
    width:20px;
    height:20px;
    margin-left: 15px;
    margin-right: 15px !important;
}
.medium-logo{
    width:30px;
    height:30px;
    border:1px solid #c2aafa;
    margin:1%;
    padding:2%;
    border-radius:50%
}

.footer{
    border-top: 1.5px solid #c2aafa;
    height: 100px;
    margin: 2%;
    padding: 2%
}

.content-text{
    color: #21242b;
    font-size: 1.1rem;
    text-align: justify;
}

.align-center{
    align-content: center;
}

a{
    color: #3b1fad; 
}

.bold{
    font-weight: bold;
}

a:hover{
 color: #c2aafa;
 text-decoration: none;   
}

.nav-text:hover{
    color:#c2aafa; 
}


.fade-in{
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.media{
    width:100%;
    text-align: justify;
}

.center{
    text-align: center;
}

